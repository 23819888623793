.navbar_div {
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;
}

.navbar_container {
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;
}

.navbar_logo {
    color: #dfdfdf;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-left: 24px;
    font-weight: bold;
    text-decoration: none;
    outline: none;
}

.navbar_mobileIcon {
    display: none;
}

.navbar_menu {
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;
}

.navbar_item {
    height: 80px;
}

.navbar_links {
    color: #dfdfdf;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
}

.navbar_links:hover {
    transition: all 0.1s ease-in-out;
    border-bottom: 3px solid #dfdfdf;
}

.navbar_links.active {
    border-bottom: 3px solid #dfdfdf;
}

.navbar_buttonDiv {
    display: flex;
    align-items: center;
}

.navbar_buttonDiv > * {
    font-size: 1rem;
    text-align: center;
    color: #dfdfdf;
    margin: 5px auto;
    text-decoration: none;
    outline: none;
    border-radius: 5px;
    background: #212121;
    padding: 10px 22px;
    display: block;
    max-width: 200px;
}

.navbar_button {
    font-size: 1rem;
    text-align: center;
    color: #dfdfdf;
    margin: 5px auto;
    text-decoration: none;
    outline: none;
    border-radius: 5px;
    background: #212121;
    padding: 10px 22px;
    display: block;
    max-width: 200px;
    cursor: pointer;
}

.navbar_button:hover {
    transition: all 0.2s ease-in-out;
    background: #616161;
    color: #dfdfdf;
}


@media screen and (max-width: 768px) {
    .navbar_mobileIcon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #dfdfdf;
    }
    .navbar_menu {
        display: none;
    }
    .navbar_buttonDiv {
        display: none;
    }
}

@media screen and (max-width: 960px) {
   .navbar_div {
        transition: 0.8s all ease;
   }
}