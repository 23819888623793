.sidebar_div {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #0d0d0d;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
}

.sidebar_iconDiv {
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2remm;
    cursor: pointer;
    outline: none;
}

.sidebar_icon {
    color: #dfdfdf;
}

.sidebar_container {
    color: #dfdfdf;
}

.sidebar_menu {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 100px);
    text-align: center;
}

.sidebar_links {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #dfdfdf;
    cursor: pointer;
}

.sidebar_links:hover {
    color: #616161;
    transition: 0.2s ease-in-out;
}

.sidebar_buttonDiv {
    display: flex;
    justify-content: center;
}

.sidebar_button {
    border-radius: 5px;
    background: #212121;
    white-space: nowrap;
    padding: 16px 64px;
    color: #dfdfdf;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
}

.sidebar_button:hover {
    transition: all 0.2s ease-in-out;
    background: #616161;
    color: #010606;
}



@media screen and (max-width: 480px) {
    .sidebar_menu {
        grid-template-rows: repeat(5, 90px);
    }
}