.about_div {
    background-color: #0d0d0d;
}

.about_container {
    display: flex;
    z-index: 1;
    height: fit-content;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 24px;
    justify-content: center;
}

.about_info {
    background: #212121;
    width: 80%;
    box-sizing: border-box;
    align-items: center;
    border-radius: 10px;
    height: fit-content;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
}

.about_title {
    color: #dfdfdf;
    font-size: 26px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    margin-top: 10px;
    text-align: center;
}

.about_subtitle {
    color: #dfdfdf;
    max-width: 768px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    line-height: 24px;
}

@media screen and (max-width: 480px) {
    .about_info {
        font-size: 1rem;
        width: 95%;
    }
    .about_subtitle {
    font-size: 1rem;
    }
 }