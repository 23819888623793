.projects_div {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #0d0d0d;
    padding: 50px 24px;
}

.projects_header {
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 64px;
}

.projects_container {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 16px;
    padding: 0 20px;
}

.projects_cards {
    background: #212121;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    border-radius: 10px;
    height: 550px;
    padding: 30px;
    margin-bottom: 25px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
}

.projects_cards:hover {
    transform: scale(1.03);
        transition: all 0.2s ease-in-out;
}

.projects_imgDiv {
    background: #dfdfdf;
    width: 170px;
    height: 170px;
    box-sizing: border-box;
    align-content: center;
    border-radius: 10px;
    justify-content: center;
    padding: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
    margin: 10px auto;
}

.projects_img {
    height: 160px;
    width: 160px;
}

.projects_title {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #dfdfdf;
    text-align: center;
}

.projects_info {
    font-size: 1rem;
    text-align: center;
    color: #dfdfdf;
    margin-bottom: 20px;
}

.projects_links {
    font-size: 1rem;
    text-align: center;
    color: #dfdfdf;
    margin: 5px auto;
    text-decoration: none;
    outline: none;
    border-radius: 5px;
    background: #0d0d0d;
    padding: 10px 22px;
    display: block;
    max-width: fit-content;
}

.projects_links:hover {
    transition: all 0.2s ease-in-out;
    background: #616161;
    color: #dfdfdf;
}




@media screen and (max-width: 480px) {
    .projects_header {
        font-size: 2rem;
    }
}

@media screen and (min-width: 800px) {
    .projects_cards {
        width: 46%;
        box-sizing: border-box;
        margin: 10px;
        margin-bottom: 25px;
    }
 }

@media screen and (min-width: 1000px) {
    .projects_cards {
        width: 30%;
        box-sizing: border-box;
        margin: 10px;
    }
 }