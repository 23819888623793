* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.App {
  text-align: center;
}

body::-webkit-scrollbar {
  display: none;
}

