.skills_div {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0d0d0d;
}

.skills_container {
    z-index: 1;
    height: fit-content;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 24px;
    justify-content: center;
}

.skills_title {
    font-size: 2.5rem;
    color: #dfdfdf;
    margin-bottom: 30px;
    text-align: center;
}

.skills_subtitle {
    color: #dfdfdf;
    font-size: 1.3rem;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 1.4px;
    margin-bottom: 25px;
    text-align: center;
}

.skills_imgDiv {
    display: flex;
    flex-wrap: wrap;
    max-width: 900px;
    margin: auto;
    justify-content: center;
    padding: 30px;
    background: #212121;
    width: 80%;
    box-sizing: border-box;
    align-items: center;
    border-radius: 10px;
    height: fit-content;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
}

.skills_imgCard {
    background: #dfdfdf;
    width: 130px;
    height: 130px;
    box-sizing: border-box;
    align-content: center;
    border-radius: 10px;
    justify-content: center;
    padding: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
    margin: 10px;
}

.skills_img {
    width: 120px;
}

.skills_imgLG {
    width: 120px;
}




@media screen and (max-width: 480px) {
    .skills_title {
        font-size: 2rem;
    }
    .skills_imgDiv {
        padding: 10px;
    }
}

@media screen and (max-width: 768px) {
    .skills_imgDiv {
        width: 90%;
    }
    .skills_imgCard {
        width: 100px;
        height: 100px;
    }
    .skills_img {
        width: 90px;
    }
    .skills_imgLG {
        width: 90px;
    }
}