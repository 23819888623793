.footer_div {
    background-color: #0d0d0d;
}

.footer_container {
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.footer_text {
    font-size: 1.2rem;
    text-align: center;
    color: #dfdfdf;
    margin-bottom: 10px;
}

.footer_linksDiv {
    display: flex;
    width: 100%;
    justify-content: center;
}

.footer_links {
    text-decoration: none;
    outline: none;
    margin: 10px;
    padding: 0;
}

.footer_icons {
    height: 50px;
    width: 50px;
    margin: 5px;
}

.footer_icons:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.2);
}


@media screen and (max-width: 768) {
    .footer_icons {
        height: 20px;
        width: 20px;
        margin: 15px;
    }
 }