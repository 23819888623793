.main_div {
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 880px;
    position: relative;
    z-index: 1;
}

.main_div::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.6) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    z-index: 2;
}

.main_background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.main_content {
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main_title {
    color: #dfdfdf;
    font-size: 80px;
    text-align: center;
}

.main_subtitle {
    margin-top: 24px;
    color: #dfdfdf;
    font-size: 40px;
    text-align: center;
    max-width: 600px;
}



@media screen and (max-width: 480px) {
    .main_title {
        font-size: 60px;
    }
    .main_subtitle {
        font-size: 25px;
    }
}

@media screen and (max-width: 768px) {
    .main_title {
        font-size: 60px;
    }
    .main_subtitle {
        font-size: 30px;
    }
}